<template>
  <v-app>
    <!-- <v-toolbar app>
      <v-toolbar-title class="headline text-uppercase">
        <span>KBT</span>
      </v-toolbar-title> 
      <v-spacer></v-spacer> 
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn flat>
          <router-link to="/">Home</router-link>
        </v-btn>
        <v-btn flat>
          <router-link to="/about">About</router-link>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar> -->


       
   <!--====== PRELOADER PART START ======-->
    <!-- <div class="preloader">
        <div class="loading">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
        </div>
    </div> -->
    <!--====== PRELOADER PART START ======-->

    <!--====== HEADER PART START ======-->

    <header class="header-area header-area-2">
        <div class="header-top pl-30 pr-30">
            <div class="row align-items-center">
                <div class="col-md-6 col-sm-7">
                    <div class="header-left-side text-center text-sm-left">
                        <ul>
                            <li><a target="_blank" href="mailto:info@kibbutztech.com"><i class="fal fa-envelope"></i> info@kibbutztech.com</a></li>
                            <li><a href="tel:'`$+9197269 80051`"><i class="fal fa-phone"></i>+91 97269 80051</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6 col-sm-5">
                    <!-- <div class="header-right-social text-center text-sm-right">
                        <ul>
                            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="#"><i class="fab fa-behance"></i></a></li>
                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                            <li><a href="#"><i class="fab fa-youtube"></i></a></li>
                        </ul>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="header-nav ">
            <div class="navigation">
                <nav class="navbar navbar-expand-lg navbar-light  ">
                 <!-- <router-link to="/"><a class="navbar-brand" >kibbutztech</a></router-link> -->

                 <!-- logo -->
                <router-link to="/"><a class="navbar-brand" ><img style="height:50px;  padding-left: 75px;
}" src="assets/images/kibbutz_logo (1).png" alt="kibbutztech"></a></router-link> 
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="toggler-icon"></span>
                        <span class="toggler-icon"></span>
                        <span class="toggler-icon"></span>
                    </button> <!-- navbar toggler -->

                    <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                        <ul class="navbar-nav m-auto">
                            <li class="nav-item active">
                              <router-link to="/">Home</router-link>
                                <!-- <a class="nav-link" href="index.php">Home</a> -->
                            </li>
                            <li class="nav-item">
                              <router-link to="/about">About</router-link>

                                <!-- <a class="nav-link" href="about.php">About</a> -->
                            </li>
                            <li class="nav-item">
                              <router-link to="/services">Services</router-link>
                                <!-- <a class="nav-link" href="services.php">Services</a> -->
                                <ul style="padding-left: 0px" class="sub-menu">
                                    <li><router-link to="/services-details?name=Mobile_App_Development">Mobile Application Development</router-link></li>
                                    <li><router-link to="/services-details?name=Web_App_Development">Web Development</router-link></li>
                                    <li><router-link to="/services-details?name=Mobile_Game_Development">Mobile Game Development</router-link></li>
                                    <li><router-link to="/services-details?name=UI_UX_Design">UI/UX Designing</router-link></li>
                                    <li><router-link to="/services-details?name=Digital_Marketing">Digital Marketing</router-link></li>
                                </ul>
                            </li>
                            <li class="nav-item">
                                <!-- <a class="nav-link" href="hire-developer.php">Hire</a> -->
                              <router-link to="/hire">Hire Developers</router-link>

                                <ul style="padding-left: 0px" class="sub-menu">
                                    <li><router-link to="/hire-details?name=Hire_React_Native_Developers">Hire React Native Developers</router-link></li>
                                    <li><router-link to="/hire-details?name=Hire_Android_Developers">Hire Android Developers</router-link></li>
                                    <li><router-link to="/hire-details?name=Hire_iOS_Developers">Hire iOS Developers</router-link></li>
                                    <li><router-link to="/hire-details?name=Hire_React_Developers">Hire React.js Developers</router-link></li>
                                    <li><router-link to="/hire-details?name=Hire_Vue_Developers">Hire Vue.js Developers</router-link></li>
                                    <li><router-link to="/hire-details?name=Hire_Node_Developers">Hire Node.js Developers</router-link></li>
                                    <li><router-link to="/hire-details?name=Hire_Laravel_Developers">Hire Laravel Developers</router-link></li>
                                    <li><router-link to="/hire-details?name=Hire_flutter_Developers">Hire Flutter Developers</router-link></li>
                                </ul>
                            </li>
                            <li class="nav-item">
                              <router-link to="/our-works">Our Work</router-link>

                                <!-- <a class="nav-link" href="about.php">About</a> -->
                            </li>
                            <li class="nav-item">
                              <router-link to="/contact">Contact</router-link>

                                <!-- <a class="nav-link" href="contact.php">Contact</a> -->
                            </li>
                        </ul>
                    </div> <!-- navbar collapse -->
                </nav>
            </div> <!-- navigation -->
        </div>
    </header>

    <!--====== HEADER PART ENDS ======-->

    <v-content>
      <router-view/>
    </v-content>

        <!--====== footer PART START ======-->

    <footer class="footer-area footer-area-2 bg_cover" style="background-image: url(assets/images/footer-bg.jpg);">
        <div class="footer-overlay">
            <div class="container position-relative">
                <div class="row">
                    <div class="col-lg-4 col-md-7">
                        <div class="widget-item-1 mt-30">
                            <router-link to="/"><a class="navbar-brand" ><img style="height:115px" src="assets/images/klogo.png" alt="kibbutztech"></a></router-link> 
                            <!-- <img src="" alt="kibbutztech"> -->
                            <p>Technology is an inevitable aspect of any sustainable business in the digital era. Kibbutz techs are committed to leveraging technology and harnessing your business's real potential.</p>
                        </div> <!-- widget item 1 -->
                    </div>
                    <div class="col-lg-3 offset-lg-1 col-md-5">
                        <div class="widget-item-2 mt-30">
                            <h4 class="title ml-3">Useful links</h4>
                            <div class="footer-list">
                                <ul>
                                    <li><router-link to="/">Home</router-link></li>
                                    <li><router-link to="/about">About Us</router-link></li>
                                    <li><router-link to="/services">Our Services</router-link></li>
                                    <li><router-link to="/hire">Hire Developers</router-link></li>
                                    <li><router-link to="/contact">Contact Us</router-link></li>

                                </ul>
                            </div>
                        </div> <!-- widget item 2 -->
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="widget-item-2  mt-30">
                            <h4 class="title"><span>Phone Number:</span></h4>
                            <P><a href="tel:'`$+9197269 80051`"><i class="fal fa-phone"></i> +91 97269 80051</a></P><br>
                             <h4 class="title">Email Address:</h4>
                            <P><a target="_blank" href="mailto:info@kibbutztech.com"><i class="fal fa-envelope"></i> info@kibbutztech.com</a></P><br>
                             <h4 class="title">Office Location:</h4>
                            <P>Kibbutz techs, Office No – 409A, The Spire, Near Sheetal Park, 150 Feet Ring Rd, Rajkot – 360005</P>
                            
<!-- 
                            
                            <p><span>For more then 30 years,</span> IT Service has been a reliable partner in the field of logistics and cargo forwarding.</p>
                            <a href="#"><i class="fal fa-angle-right"></i>Discover More</a> -->
                        </div> 
                        <!-- widget item 3 -->
                    </div>
                </div> <!-- row -->
                <div class="row">
                    <div class="col-lg-12">
                        <div class="footer-copyright">
                            <p>Copyright By@ <span>Kibbutz tech</span> - 2022</p>
                        </div> <!-- footer copyright -->
                    </div>
                </div> <!-- row -->
                <div class="shape-1"></div>
                <div class="shape-2"></div>
                <div class="shape-3"></div>
            </div> <!-- container -->
        </div>
    </footer>

    <!--====== footer PART ENDS ======-->

    <!--====== BACK TO TOP ======-->
    <div class="back-to-top back-to-top-2">
        <a href="">
            <i class="fas fa-arrow-up"></i>
        </a>
    </div>
    <!--====== BACK TO TOP ======-->

   
  </v-app>
</template>

<script>
// import HelloWorld from "./components/HelloWorld";

export default {
  name: "App",
  components: {
    // HelloWorld
  },
  data() {
    return {
      //
    };
  }
};
</script>