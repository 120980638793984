<template>
  <!-- <HelloWorld /> -->
  <div>
    <!--====== BANNER PART START ======-->
    <div class="banner-area">
      <div class="container position-relative">
        <div class="row">
          <div class="col-lg-7">
            <div class="banner-content pt-100">
              <h1
                class="title wow fadeInLeft"
                data-wow-duration="2s"
                data-wow-delay=".5s"
              >Building business. Leveraging technology</h1>
              <span
                style="z-index:100;"
                class="wow fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay=".1s"
              >
                In the digital age, technology is an unavoidable part of any successful
                organisation. Taking full advantage of technology and harnessing your business's
                true potential is our goal. The marketing ecosystem is changing as a result of
                technological advancements, and Kibbutz is keeping up with it. Kibbutz, a
                technology development firm with experience in a variety of platforms and tools,
                is here to assist you! With our new-age tech integrations and innovative
                solutions, we can propel your business forward.
              </span>
            </div>
            <!-- banner content -->
          </div>
        </div>
        <!-- row -->
        <div style="z-index:0; width:550px;" class="banner-shape">
          <img
            src="https://www.matellio.com/assets/matellio-ent/images/Dedicated%20Developers.svg"
            alt="Blog"
          />
          <!-- <img src="assets/images/mainindex.jpg" alt="image"> -->
        </div>
      </div>
      <!-- container -->
    </div>

    <!--====== BANNER PART ENDS ======-->

    <!--====== WHO WE ARE PART START ======-->

    <div class="who-we-are-area pt-110 pb-120">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="section-title text-center">
              <span>HIRE OUR DEVELOPERS</span>
              <h2 class="title">Hire Developer</h2>
            </div>
            <!-- section title -->
          </div>
        </div>
        <!-- row -->
        <div class="row">
          <div
            onclick="redirect_page('Hire_React_Native_Developers')"
            class="col-lg-3 col-md-6 col-sm-6 wow slideInUp"
            data-wow-duration=".5s"
            data-wow-delay="0s"
          >
            <router-link to="/hire-details?name=Hire_React_Native_Developers">
              <!-- <a href="hire-details?name=Hire_React_Native_Developers"> -->
              <div style="height:300px" class="what-we-do-item text-center mt-30">
                <i aria-hidden="true">
                  <img src="assets/images/react_icon.png" alt />
                </i>
                <h5 class="title">Hire our React Native Developers</h5>
                <p>
                  Raise the bar of mobile apps with React
                  Native
                </p>
              </div>
              <!-- </a> -->
            </router-link>
          </div>

          <div
            class="col-lg-3 col-md-6 col-sm-6 wow slideInUp"
            data-wow-duration=".5s"
            data-wow-delay="0s"
          >
            <router-link to="/hire-details?name=Hire_Vue_Developers">
              <!-- <a href="hire-details?name=Hire_Vue_Developers"> -->
              <div style="height:300px" class="what-we-do-item text-center mt-30">
                <i aria-hidden="true">
                  <img src="assets/images/vue_icon.png" alt />
                </i>
                <h5 class="title">Hire our Vue.js Developers</h5>
                <p>Enhance your applications with Vue.js</p>
              </div>
              <!-- </a> -->
            </router-link>
          </div>

          <div
            onclick="redirect_page('Hire_Node_Developers')"
            class="col-lg-3 col-md-6 col-sm-6 wow slideInUp"
            data-wow-duration="1s"
            data-wow-delay="0s"
          >
            <router-link to="/hire-details?name=Hire_Node_Developers">
              <!-- <a href="hire-details?name=Hire_Node_Developers"> -->
              <div style="height:300px" class="what-we-do-item text-center mt-30">
                <i aria-hidden="true">
                  <img src="assets/images/node_icon.png" alt />
                </i>
                <!-- <i class="fal fa-fingerprint"></i> -->
                <h5 class="title">Hire our Node.js Developers</h5>
                <p>
                  Be a long-term player in business with Node.js
                  Developers
                </p>
              </div>
              <!-- </a> -->
            </router-link>
          </div>
          <div
            onclick="redirect_page('Hire_React_Developers')"
            class="col-lg-3 col-md-6 col-sm-6 wow slideInUp"
            data-wow-duration="2s"
            data-wow-delay="0s"
          >
            <router-link to="/hire-details?name=Hire_React_Developers">
              <!-- <a href="hire-details?name=Hire_React_Developers"> -->
              <div style="height:300px" class="what-we-do-item text-center mt-30">
                <i aria-hidden="true">
                  <img src="assets/images/react_icon.png" alt />
                </i>
                <h5 class="title">Hire our React.js Developers</h5>
                <p>
                  Create the history of excellence on the web
                  with React.js
                </p>
              </div>
              <!-- </a> -->
            </router-link>
          </div>
        </div>

        <div class="row">
          <div
            onclick="redirect_page('Hire_Android_Developers')"
            class="col-lg-3 col-md-6 col-sm-6 wow slideInUp"
            data-wow-duration="1s"
            data-wow-delay="0s"
          >
            <router-link to="/hire-details?name=Hire_Android_Developers">
              <!-- <a href="hire-details?name=Hire_Android_Developers"> -->
              <div style="height:300px" class="what-we-do-item text-center mt-30">
                <i aria-hidden="true">
                  <img src="assets/images/android_icon.png" alt />
                </i>
                <h5 class="title">Hire our Android Developers</h5>
                <p>
                  Let Your Business Reach New Heights with
                  Android's Popularity
                </p>
              </div>
              <!-- </a> -->
            </router-link>
          </div>
          <div
            onclick="redirect_page('Hire_iOS_Developers')"
            class="col-lg-3 col-md-6 col-sm-6 wow slideInUp"
            data-wow-duration="1.5s"
            data-wow-delay="0s"
          >
            <router-link to="/hire-details?name=Hire_iOS_Developers">
              <!-- <a href="hire-details?name=Hire_iOS_Developers"> -->
              <div style="height:300px" class="what-we-do-item text-center mt-30">
                <i aria-hidden="true">
                  <img src="assets/images/apple_icon.png" alt />
                </i>
                <h5 class="title">Hire our iOS Developers</h5>
                <p>
                  Create apps for some of the most dependable
                  mobile devices
                </p>
              </div>
              <!-- </a> -->
            </router-link>
          </div>

          <div
            onclick="redirect_page('Hire_Laravel_Developers')"
            class="col-lg-3 col-md-6 col-sm-6 wow slideInUp"
            data-wow-duration="1.5s"
            data-wow-delay="0s"
          >
            <router-link to="/hire-details?name=Hire_Laravel_Developers">
              <!-- <a href="hire-details?name=Hire_Laravel_Developers"> -->
              <div style="height:300px" class="what-we-do-item text-center mt-30">
                <i aria-hidden="true">
                  <img src="assets/images/laravel_icon.png" alt />
                </i>
                <h5 class="title">Hire our Laravel Developers</h5>
                <p>
                  Create a new dimension to your business with
                  Laravel technology
                </p>
              </div>
              <!-- </a> -->
            </router-link>
          </div>

          <div
            onclick="redirect_page('Hire_flutter_Developers')"
            class="col-lg-3 col-md-6 col-sm-6 wow slideInUp"
            data-wow-duration="1.5s"
            data-wow-delay="0s"
          >
            <router-link to="/hire-details?name=Hire_flutter_Developers">
              <!-- <a href="hire-details?name=Hire_Laravel_Developers"> -->
              <div style="height:300px" class="what-we-do-item text-center mt-30">
                <i aria-hidden="true">
                  <img src="assets/images/flutter_icon.png" alt />
                </i>
                <h5 class="title">Hire our Flutter Developers</h5>
                <p>Shine like gold in your business with Flutter</p>
              </div>
              <!-- </a> -->
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!--====== WHO WE ARE PART ENDS ======-->

    <!--====== WHY CHOOSE PART START ======-->

    <div class="why-choose-area">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="section-title text-center">
              <h2 class="title">Why will you choose Kibbutz Tech?</h2>
              <p style="color:white">
                We are built with extensive knowledge to ensure that you never lose great ideas
                or context. We do not have any hidden fees or charges like others. We provide
                you with the best security features on our website and design it with cutting-edge
                technology.
              </p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div
            class="col-lg-4 col-md-6 col-sm-9 wow slideInUp"
            data-wow-duration=".5s"
            data-wow-delay="0s"
          >
            <div class="single-choose text-center mt-30">
              <div class="icon-box">
                <span></span>
                <i class="fal fa-laptop-code"></i>
              </div>
              <h4 class="title">Best Quality</h4>
              <p>
                Our extensive internal framework library, best-in-class
                infrastructure, and ten years of experience in the IT software development
                sector enable us to consistently produce solutions of the highest calibre.
              </p>
            </div>
          </div>
          <div
            class="col-lg-4 col-md-6 col-sm-9 wow slideInUp"
            data-wow-duration="1s"
            data-wow-delay="0s"
          >
            <div class="single-choose text-center mt-30">
              <div class="icon-box">
                <span></span>
                <i class="fal fa-server"></i>
              </div>
              <h4 class="title">Strong Expertise</h4>
              <p>
                With a core field of expertise in Ruby on React, Vue,
                Node, and Laravel, our coding experts stay on the cutting edge of the
                market's newest and most successful technology trends and popular
                applications.
              </p>
            </div>
          </div>
          <div
            class="col-lg-4 col-md-6 col-sm-9 wow slideInUp"
            data-wow-duration="1.5s"
            data-wow-delay="0s"
          >
            <div class="single-choose text-center mt-30">
              <div class="icon-box">
                <span></span>
                <i class="fal fa-tools"></i>
              </div>
              <h4 class="title">Result Oriented</h4>
              <p>
                We design digitally universal products rather than
                features. These are real-world issues with futuristic solutions. We make
                sure to offer them notwithstanding any circumstances that can impede
                growth or advancement.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--====== WHY CHOOSE PART ENDS ======-->

    <!--====== WE ARE THE BEST start ======-->

    <div class="services-item-area pt-90 pb-120">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="section-title text-center">
              <span>WE ARE provide</span>
              <h2 class="title">Our Services</h2>
            </div>
            <!-- section title -->
          </div>
        </div>
        <!-- row -->
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-6 col-sm-8">
            <div class="single-services-item mt-30">
              <router-link to="/services-details?name=Mobile_App_Development">
                <!-- <a href="services-details?name=Mobile_App_Development"> -->
                <img style="width: 450px" src="assets/images/mobiledeveloper.png" alt />
                <!-- </a> -->
                <!-- <a href="services-details?name=Mobile_App_Development"> -->
                <div class="services-overlay">
                  <i class="fal fa-mobile"></i>
                  <h4 class="title">Mobile Application Development</h4>
                  <p>
                    Quick and
                    trustworthy mobile apps for your company's requirements
                  </p>
                </div>
                <!-- </a> -->
              </router-link>
            </div>
            <!-- single services -->
          </div>

          <div class="col-lg-4 col-md-6 col-sm-8">
            <div class="single-services-item mt-30">
              <router-link to="/services-details?name=Web_App_Development">
                <!-- <a href="services-details?name=Web_App_Development"> -->
                <img style="width: 450px" src="assets/images/webdeveloper2.png" alt />
                <!-- </a> -->
                <!-- <a href="services-details?name=Web_App_Development"> -->
                <div class="services-overlay">
                  <i class="fal fa-browser"></i>
                  <h4 class="title">
                    Web
                    <br />Development
                  </h4>
                  <p>
                    Be the first organic search result in
                    Google
                  </p>
                </div>
                <!-- </a> -->
              </router-link>
            </div>
            <!-- single services -->
          </div>
          <div class="col-lg-4 col-md-6 col-sm-8">
            <div class="single-services-item mt-30">
              <router-link to="/services-details?name=Mobile_Game_Development">
                <!-- <a href="services-details?name=Mobile_Game_Development"> -->
                <img src="assets/images/game.png" alt />
                <!-- </a> -->
                <!-- <a href="services-details?name=Mobile_Game_Development"> -->
                <div class="services-overlay">
                  <i class="fal fa-gamepad"></i>
                  <h4 class="title">Mobile Game Development</h4>
                  <p>We develop a successful mobile game and turn it into a profitable business</p>
                </div>
                <!-- </a> -->
              </router-link>
            </div>
            <!-- single services -->
          </div>
          <div class="col-lg-4 col-md-6 col-sm-8">
            <div class="single-services-item mt-30">
              <router-link to="/services-details?name=UI_UX_Design">
                <!-- <a href="services-details?name=UI_UX_Design"> -->
                <img src="assets/images/uiux2.png" alt />
                <!-- </a> -->
                <!-- <a href="services-details?name=UI_UX_Design"> -->
                <div class="services-overlay">
                  <i class="fal fa-palette"></i>
                  <h4 class="title">
                    UI/UX
                    <br />Designing
                  </h4>
                  <p>
                    We give an impactful look to
                    your business
                  </p>
                </div>
                <!-- </a> -->
              </router-link>
            </div>
            <!-- single services -->
          </div>
          <div class="col-lg-4 col-md-6 col-sm-8">
            <div class="single-services-item mt-30" style="weight=3000px height=2000px">
              <router-link to="/services-details?name=Digital_Marketing">
                <!-- <a href="services-details?name=UI_UX_Design"> -->
                <img src="assets/images/marketing.png" alt="image" />
                <!-- </a> -->
                <!-- <a href="services-details?name=UI_UX_Design"> -->
                <div class="services-overlay">
                  <i class="fal fa-bullhorn"></i>
                  <h4 class="title">
                    Digital
                    <br />Marketing
                  </h4>
                  <p>
                    Our
                    digital marketing strategies will be the best in thousands to increase the
                    impact of your business.
                  </p>
                </div>
                <!-- </a> -->
              </router-link>
            </div>
            <!-- single services -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </div>

    <!--====== WE ARE THE BEST ENDS ======-->

    <!--====== NEWS PART START ======-->
    <div class="news-area news-area-2">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="section-title text-center">
              <span style="color:#fff">Case Study</span>
              <h2 class="title" style="color:#fff">Our Recent Work</h2>
            </div>
          </div>
        </div>
        <div class="row pt-3 mx-2">
          <div class="col-lg-3 mb-4">
            <div class="intri-content pa-2" style="border-style: solid; background-color: white;">
              <a href="http://www.hemithexim.com/" target="_blank">
                <img style="height:100px" src="assets/images/hemithexim.png" />
              </a>
            </div>
          </div>

          <div class="col-lg-3 mb-4">
            <div class="intri-content pa-2" style="border-style: solid; background-color: white;">
              <a href="https://app.kinderway.com/" target="_blank">
                <img style="height:100px" src="assets/images/kinderway_logo1.png" />
              </a>
            </div>
          </div>

          <div class="col-lg-3 mb-4">
            <div class="intri-content pa-2" style="border-style: solid; background-color: white;">
              <a href="https://innoplore.com/" target="_blank">
                <img style="height:100px" src="assets/images/innoplore-logo.png" />
              </a>
            </div>
          </div>

          <div class="col-lg-3 mb-4">
            <div class="intri-content pa-2" style="border-style: solid;  background-color: white;">
              <a href="https://www.meragi.com/" target="_blank">
                <img style="height:100px" src="assets/images/miragi.png" />
              </a>
            </div>
          </div>

          <div class="col-lg-3 mb-4">
            <div class="intri-content pa-2" style="border-style: solid;">
              <a href="http://bellottitile.com/" target="_blank">
                <img style="height:100px" src="assets/images/bellotti-logo.png" />
              </a>
            </div>
          </div>

          <div class="col-lg-3 mb-4">
            <div class="intri-content pa-2" style="border-style: solid; background-color: white;">
              <a href="https://blessedhearts.in/" target="_blank">
                <img
                  click="openImg()"
                  style="height:100px"
                  src="assets/images/blessedhearts_logo.png"
                />
              </a>
            </div>
          </div>

          <div class="col-lg-3 mb-4">
            <div class="intri-content pa-2" style="border-style: solid; background-color: white;">
              <a href="https://www.optimize-courier.com/" target="_blank">
                <img style="height:100px" src="assets/images/optimize.png" />
              </a>
            </div>
          </div>

          <div class="col-lg-3 mb-4">
            <div class="intri-content pa-2" style="border-style: solid; ">
              <a href="http://livart.in/" target="_blank" style="padding-left: 40px;">
                <img style="height:100px" src="assets/images/livart.png" />
              </a>
            </div>
          </div>

          <div class="col-lg-3 mb-4">
            <div class="intri-content pa-2" style="border-style: solid;">
              <a href="http://www.palplastind.com/" target="_blank">
                <img style="height:100px" src="assets/images/palplastind.png" />
              </a>
            </div>
          </div>

          <div class="col-lg-3 mb-4">
            <div class="intri-content pa-2" style="border-style: solid;">
              <a href="https://aladinseo.com" target="_blank" style=" padding-left: 60px;">
                <img style="height:100px" src="assets/images/aladinseo.png" />
              </a>
            </div>
          </div>

          <div class="col-lg-3 mb-4">
            <div class="intri-content pa-2" style="border-style: solid; background-color: white;">
              <a href="https://orunexim.com/" target="_blank">
                <img style="height:100px" src="assets/images/orunexim.png" />
              </a>
            </div>
          </div>

          <div class="col-lg-3 mb-4">
            <div class="intri-content pa-2" style="border-style: solid;">
              <a href="http://astia.in/" target="_blank">
                <img style="height:100px" src="assets/images/astia_logo.png" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- container -->
    </div>

    <!--====== NEWS PART ENDS ======-->
    <!--====== Clients PART START ======-->

    <div class="services-area gray-bg pt-115 pb-120">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="section-title text-center">
              <span>Testimonials</span>
              <h2 class="title">Love from Clients</h2>
            </div>
            <!-- section title -->
          </div>
        </div>
        <!-- row -->
        <div class="row justify-content-center">
          <div
            class="col-lg-4 col-md-6 col-sm-8 wow fadeInUp"
            data-wow-duration=".1s"
            data-wow-delay=".3s"
          >
            <div class="single-services text-center mt-30">
              <!-- <img src="assets/images/services-1.png" alt="image"> -->
              <h4 class="title">Alpa Patel</h4>
              <h5>hemithexim</h5>
              <br />
              <br />
              <p>
                Kibbutz Tech has done an excellent job while creating my mobile app and
                mobile game. This is exactly what I wanted, and they created it in the same
                manner. They provided me with excellent post-work support and
                guidance. I strongly recommend them to everyone, and their developers
                are outstanding.
              </p>
            </div>
            <!-- singke services -->
          </div>
          <div
            class="col-lg-4 col-md-6 col-sm-8 wow fadeInUp"
            data-wow-duration=".1s"
            data-wow-delay=".3s"
          >
            <div class="single-services text-center mt-30">
              <!-- <img src="assets/images/services-1.png" alt="image"> -->
              <h4 class="title">Suman Patel</h4>
              <h5>kinderway</h5>
              <br />
              <br />
              <p>
                They finished all of the projects in record time and with high efficiency. I
                was sceptical of their developers' creativity about the website design
                because I am extremely picky, but after they delivered the project, I was
                speechless with gratitude. I won't get tired of recommending them; I'll do
                it a thousand times. Thanks a lot.
              </p>
            </div>
            <!-- singke services -->
          </div>
          <div
            class="col-lg-4 col-md-6 col-sm-8 wow fadeInUp"
            data-wow-duration=".5s"
            data-wow-delay=".4s"
          >
            <div class="single-services single-services-2 text-center mt-30">
              <!-- <img src="assets/images/services-2.png" alt="image"> -->
              <h4 class="title">Prafulbhai Bhadaja</h4>
              <h5>innoplore</h5>
              <br />
              <br />
              <p>
                Various marketing companies we've worked with previously failed to
                deliver results, and I'm glad Kibbutz is. They did an amazing job redesigning
                our website, taking it from plain and functional to incredibly professional.
                We would strongly recommend Kibbutz to any company that wants to
                improve their internet traffic or business. Our vision comes to life through
                them.
              </p>
            </div>
            <!-- singke services -->
          </div>
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->

    <!--====== Clients PART ENDS ======-->
  </div>
</template>

<script>
// import HelloWorld from '../components/HelloWorld'

export default {
  components: {
    // HelloWorld
  },
  methods: {},
  data() {
    return {};
  },
  mounted() {
    // console.log("this.$route.params",this.$route.params.name)
    window.scrollTo(0, 0);
  }
};
</script>

<style >
.who-we-are-area .container a{ 
  width: 100% !important;
}

</style>